import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpStatusCode,
} from '@angular/common/http';
import { catchError } from 'rxjs';

export const httpErrorInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      if (err?.status === HttpStatusCode.Unauthorized) {
        localStorage.clear();
        window.location.reload();
      }

      throw err;
    })
  );
};
