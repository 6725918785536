<header class="main-header">
  <div class="nav-bg">
    <div class="main-nav">
      <!-- Logo -->
      <div class="brand">
        <img class="logo" src="/assets/img/hi-logo.svg" alt="Hello Insight" />
      </div>
      <!-- Menu-->
      <nav class="links">
        <ul class="links-menu">
          @for (nav of mainNav; track nav; let index = $index) {
          <li [ngClass]="{ active: nav.active || nav.expanded }">
            <a
              (click)="selectNav(nav, $event)"
              [id]="'mainMenu' + index"
              [class]="'nav-' + nav.name"
              [ngClass]="{ resources: nav.resources }"
              [routerLinkActive]="'active'"
            >
              <mat-icon svgIcon="{{ nav.img }}" class="links-icon"> </mat-icon>
              {{ nav.name }}
            </a>
            <!-- Filter -->
            @if (nav.expanded) {
            <div
              hiClickOutside
              (clickOutside)="nav.expanded = false"
              class="header-menu-dropdown-content"
            >
              @for (menu of expandedMenuItems; track menu; let lastItem = $last)
              {
              <div
                class="header-menu-item"
                (click)="goUrl(menu); nav.expanded = false"
                [ngClass]="{
                  'menu-item-disabled': menu.disabled,
                  has_description: !!menu.description
                }"
              >
                <div>
                  <div class="menu-item-header">
                    <mat-icon
                      svgIcon="{{ menu.img }}"
                      class="menu-item-icon"
                      aria-label="dropdown-icon"
                    >
                    </mat-icon>
                    <a
                      class="menu-item-name"
                      id="{{ menu.name.split(' ').join('_') }}"
                      >{{ menu.name }}</a
                    >
                  </div>
                  <div
                    class="menu-item-description"
                    [innerHTML]="menu.description"
                  ></div>
                </div>
                @if (menu.url != 'NA') {
                <mat-icon
                  svgIcon="right-arrow"
                  class="icon-caret-right-small"
                  aria-label="dropdown-icon"
                >
                </mat-icon>
                }
                <!--<div class="menu-item-divider"></div>-->
              </div>
              }
            </div>
            }
          </li>
          }
        </ul>
      </nav>
      <!-- Profile -->
      <div class="profile">
        <div class="profile-org">{{ getUserOrg() }}</div>
        <div
          class="profile-user"
          e2e-data="profile"
          (click)="toggleUserMenu($event)"
        >
          <div class="profile-name">
            {{ getUserProfile() }}
          </div>
          <div
            class="profile-pic"
            (click)="toggleUserMenu($event)"
            ng-style="getProfilePicStyles()"
          >
            @if (!getUserPicture()) {
            <span class="initials">{{ getInitials() }}</span>
            } @if (getUserPicture()) {
            <img
              [src]="getUserPicture()"
              width="30"
              height="30"
              style="border-radius: 50%"
            />
            }
          </div>
          <div class="profile-dropdown" (click)="toggleUserMenu($event)">
            @if (userMenuToggled) {
            <ul
              class="user-menu"
              [ngClass]="{ initiatives: showManageInitiatives }"
              (mouseleave)="userMenuToggled = false"
            >
              @for (menu of userMenu; track menu) {
              <li
                [ngClass]="{
                  upgrade: menu.name === 'Upgrade Plan',
                  orange: menu.name === 'Admin'
                }"
              >
                <a
                  (click)="clickMenu(menu, $event)"
                  [class]="'header' + menu.name"
                >
                  {{ menu.name }}
                </a>
              </li>
              }
            </ul>
            }
          </div>
          <i class="fa fa-chevron-down" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  </div>
</header>
