import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../shared/services/api/api.service';
import { Program } from '../../shared/models/program';
import { Group } from '../../shared/models/group';

declare const ENABLE_V2_5_REPORTS;

@Injectable({
  providedIn: ApiService,
})
export class DashboardService {
  constructor(private api: ApiService) {}

  public getInitiatives(network_id): Observable<any> {
    const url = `intermediary/${network_id}/initiatives/`;
    return this.api.get(url);
  }

  public getInitiativesList(network_id): Observable<any> {
    const url = `intermediary/${network_id}/initiative-lists/`;
    return this.api.get(url);
  }

  public getGettingReady(init_id): Observable<any> {
    const url = `initiative/${init_id}/getting-ready/`;
    return this.api.get(url);
  }

  public getCollectingSurveys(init_id): Observable<any> {
    const url = `initiative/${init_id}/collecting-surveys/`;
    return this.api.get(url);
  }
}
