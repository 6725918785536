import { HttpInterceptorFn } from '@angular/common/http';

declare const API_URL;

export const httpVersionInterceptor: HttpInterceptorFn = (req, next) => {
  const frontendVersion: string = localStorage.getItem('frontend_version');

  if (req.url.includes(API_URL)) {
    req = req.clone({
      setHeaders: {
        'Frontend-Version': frontendVersion || '0',
      },
    });
  }
  return next(req);
};
