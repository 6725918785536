import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SessionService } from '../session/session.service';

declare const API_URL;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = API_URL;

  constructor(private http: HttpClient, private session: SessionService) {}

  private createHttpOptions(payload?: string) {
    const token = localStorage.token ? this.session.getToken() : '';

    const headersMap = {
      'Content-Type': 'application/json',
      Authorization: token,
    };

    if (payload === 'file') {
      delete headersMap['Content-Type'];
    }

    if (this.session.entered_as() && !!this.session.getUser())
      headersMap['EnteredAs'] = this.session.getUser().email;

    return {
      headers: new HttpHeaders(headersMap),
    };
  }

  get<T>(url: string, params?: object): Observable<T> {
    let httpOptions = this.createHttpOptions();
    let httpParams = new HttpParams();

    if (params)
      for (let par in params) httpParams = httpParams.set(par, params[par]);

    httpParams = httpParams.set('_', '' + Date.now());
    httpOptions['params'] = httpParams;

    const finalUrl = `${this.baseUrl}/${url}`;
    return this.http.get<T>(finalUrl, httpOptions).pipe(tap());
  }

  get2<T>(url: string): Observable<T> {
    let httpOptions = this.createHttpOptions();
    let httpParams = new HttpParams();
    httpParams = httpParams.set('_', '' + Date.now());
    httpOptions['params'] = httpParams;
    httpOptions['Access-Control-Allow-Credentials'] = true;
    httpOptions['withCredentials'] = true;
    const finalUrl = `${this.baseUrl}/${url}`;
    return this.http.get<T>(finalUrl, httpOptions).pipe(tap());
  }

  get3<T>(url: string): Observable<T> {
    let httpOptions = this.createHttpOptions();
    let httpParams = new HttpParams();
    httpOptions['params'] = httpParams;
    const finalUrl = `${this.baseUrl}/${url}`;
    return this.http.get<T>(finalUrl, httpOptions).pipe(tap());
  }

  getItems<T>(url: string, params?: object): Observable<T> {
    return this.get<any>(url, params).pipe(
      map((response) => {
        // TODO: Change in Eve Removal:
        // https://github.com/Algorhythm-IO/yd2/issues/814
        response.data = response.data._items;
        return response;
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  post<T>(url: string, data?: any): Observable<T> {
    const finalUrl = `${this.baseUrl}/${url}`;
    return this.http
      .post<T>(finalUrl, data, this.createHttpOptions())
      .pipe(tap());
  }

  postFile<T>(url: string, data: Object): Observable<T> {
    const finalUrl = `${this.baseUrl}/${url}`;
    let options = this.createHttpOptions('file');
    options['reportProgress'] = true;
    options['observe'] = 'events';
    return this.http.post<any>(finalUrl, data, options).pipe(tap());
  }

  postFile2<T>(url: string, data: Object): Observable<T> {
    const finalUrl = `${this.baseUrl}/${url}`;
    let options = this.createHttpOptions('file');
    return this.http.post<any>(finalUrl, data, options).pipe(tap());
  }

  patch<T>(model, data): Observable<T> {
    let model_url = model._links.self.href;
    const finalUrl = `${this.baseUrl}/${model_url}`;

    let httpOptions = this.createHttpOptions();
    httpOptions['If-Match'] = model._etag;
    return this.http.patch<T>(finalUrl, data, httpOptions);
  }

  patch2<T>(url: string, data: Object): Observable<T> {
    const finalUrl = `${this.baseUrl}/${url}`;
    let httpOptions = this.createHttpOptions();
    return this.http.patch<T>(finalUrl, data, httpOptions).pipe(tap());
  }

  delete<T>(url: string): Observable<T> {
    const finalUrl = `${this.baseUrl}/${url}`;
    return this.http.delete<T>(finalUrl, this.createHttpOptions());
  }

  delete2<T>(url: string, data: Object): Observable<T> {
    const finalUrl = `${this.baseUrl}/${url}`;
    let httpOptions = this.createHttpOptions();
    httpOptions['body'] = data;
    return this.http.delete<T>(finalUrl, httpOptions);
  }

  hasReport() {
    const endpoint = 'user/has-report/';

    return this.get(endpoint);
  }
}
