import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { httpVersionInterceptor } from './core/interceptors/http-version/http-version.interceptor';
import { httpErrorInterceptor } from './core/interceptors/http-error/http-error.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([httpVersionInterceptor, httpErrorInterceptor])
    ),
    provideAnimationsAsync(),
  ],
};
