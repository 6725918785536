import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/authentication/authentication.routes').then(
        (m) => m.authenticationRoutes
      ),
  },
  {
    path: 'program',
    loadChildren: () =>
      import('./modules/program/program.routes').then((m) => m.programRoutes),
  },
  {
    path: 'data-visibility/:org_id',
    loadComponent: () =>
      import('./modules/data-visibility/data-visibility.component').then(
        (m) => m.DataVisibilityComponent
      ),
  },
  {
    path: 'dashboard',
    loadComponent: () =>
      import('./modules/dashboard/dashboard.component').then(
        (m) => m.DashboardComponent
      ),
  },
  {
    path: 'academy',
    loadComponent: () =>
      import('./modules/academy/academy.component').then(
        (m) => m.AcademyComponent
      ),
  },
  {
    path: 'responses/:id',
    loadComponent: () =>
      import('./modules/responses/responses.component').then(
        (m) => m.ResponsesComponent
      ),
  },
  {
    path: 'enter-as',
    loadComponent: () =>
      import('./modules/enter-as/enter-as.component').then(
        (m) => m.EnterAsComponent
      ),
  },
  {
    path: 'new-organization',
    loadComponent: () =>
      import('./modules/new-user/new-user.component').then(
        (m) => m.NewUserComponent
      ),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./modules/account-management/account-management.routes').then(
        (m) => m.accountManagementRoutes
      ),
  },
  {
    path: 'subscriptions/:org_id',
    loadComponent: () =>
      import('./modules/subscriptions/subscriptions.component').then(
        (m) => m.SubscriptionsComponent
      ),
  },
  {
    path: 'confirm-email/:email',
    loadComponent: () =>
      import('./modules/confirm-email/confirm-email.component').then(
        (m) => m.ConfirmEmailComponent
      ),
  },
  {
    path: 'networks',
    loadChildren: () =>
      import('./modules/network/network.routes').then((m) => m.networkRoutes),
  },
  {
    path: 'manage-initiative-member',
    loadChildren: () =>
      import(
        './modules/manage-initiative-member/manage-initiative-member.routes'
      ).then((m) => m.manageInitiativeMemberRoutes),
  },
  {
    path: 'manage-initiatives',
    loadComponent: () =>
      import('./modules/manage-initiatives/manage-initiatives.component').then(
        (m) => m.ManageInitiativesComponent
      ),
  },
  {
    path: 'get-started',
    loadComponent: () =>
      import(
        './modules/network-get-started/network-get-started.component'
      ).then((m) => m.NetworkGetStartedComponent),
  },
  {
    path: 'network',
    loadComponent: () =>
      import('./modules/dashboard-im/dashboard-im.component').then(
        (m) => m.DashboardIM
      ),
  },
  {
    path: 'portfolio',
    loadChildren: () =>
      import('./modules/reports/portfolio/portfolio.routes').then(
        (m) => m.portfolioRoutes
      ),
  },
  {
    path: 'advanced-reports',
    loadChildren: () =>
      import('./modules/reports/portfolio/portfolio.routes').then(
        (m) => m.portfolioRoutes
      ),
  },
  {
    path: 'assign-sponsored-subscription/:id',
    loadComponent: () =>
      import('./modules/scholarship/scholarship.component').then(
        (m) => m.Scholarship
      ),
  },
  {
    path: 'scholarship/:id',
    loadChildren: () =>
      import('./modules/scholarship/scholarship.component').then(
        (m) => m.Scholarship
      ),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('./modules/reports/reports.routes').then((m) => m.reportsRoutes),
  },
  {
    path: 'shared',
    loadChildren: () =>
      import('./modules/reports/reports.routes').then((m) => m.reportsRoutes),
  },
  {
    path: 'setup-account/:token',
    loadComponent: () =>
      import('./modules/acc-setup/acc-setup.component').then(
        (m) => m.AccSetupComponent
      ),
  },
  {
    path: 'org-setup',
    loadComponent: () =>
      import('./modules/org-setup/org-setup.component').then(
        (m) => m.OrgSetupComponent
      ),
  },
  {
    path: 'setup-network-account/:token',
    loadComponent: () =>
      import('./modules/net-setup/net-setup.component').then(
        (m) => m.NetSetupComponent
      ),
  },
  {
    path: 'flask-admin',
    loadComponent: () =>
      import('./modules/flask-admin/flask-admin.component').then(
        (m) => m.FlaskAdminComponent
      ),
  },
  {
    path: 'reset-password/:token',
    loadComponent: () =>
      import(
        './modules/authentication/reset-password/reset-password.component'
      ).then((m) => m.ResetPasswordComponent),
  },
  {
    path: 'oops',
    loadComponent: () =>
      import(
        './modules/system/errors/internal-server-error/internal-server-error.component'
      ).then((m) => m.InternalServerErrorComponent),
  },
  {
    path: '404',
    loadComponent: () =>
      import(
        './modules/system/errors/page-not-found/page-not-found.component'
      ).then((m) => m.PageNotFoundComponent),
  },
  {
    path: '**',
    redirectTo: '404',
  },
];
