import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private clickedReportsTab: boolean = false;
  private reportsTabClick = new BehaviorSubject<boolean>(false);
  reportsTabClick$ = this.reportsTabClick.asObservable();

  constructor() {}

  setClick() {
    this.clickedReportsTab = !this.clickedReportsTab;
    this.reportsTabClick.next(this.clickedReportsTab);
  }
}
