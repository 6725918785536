import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import {
  Router,
  NavigationEnd,
  RoutesRecognized,
  RouterOutlet,
  RouterLink,
} from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { SessionService } from './shared/services/session/session.service';
import { HeaderComponent } from './modules/layout/header/header.component';
import { DashboardService } from './modules/dashboard-im/dashboard-im.services';
import { MixpanelService } from './shared/services/mixpanel/mixpanel.service';

declare const INTERCOM;
declare const HUBSPOT;
declare var jQuery;
@Component({
  selector: 'root-ng2',
  templateUrl: './root.component.html',
  standalone: true,
  imports: [RouterOutlet, RouterLink, HeaderComponent],
  providers: [DashboardService],
})
export class RootComponent implements OnInit, AfterViewInit {
  public headerShow: boolean = true;
  public isSharedReport: boolean = false;

  private router = inject(Router);
  private sessionService = inject(SessionService);
  private mixpanel = inject(MixpanelService);

  ngAfterViewInit(): void {
    if (localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));

      delete user.organization;
      this.mixpanel.setIdentity(user.id);
      this.mixpanel.setUser(user);
    }
  }

  ngOnInit() {
    let count = 0;
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        const preUrl = events[0].urlAfterRedirects;
        if (preUrl.indexOf('/account') === -1) {
          localStorage.setItem('_previousUrl', events[0].urlAfterRedirects);
        }
      });

    this.router.events.subscribe((evt) => {
      //Temporal Fix - Migrating Roles
      if (localStorage.getItem('role')) {
        const role: string = localStorage.getItem('role');
        const roleMap = {
          IM: 'NA',
          OL: 'AA',
          PL: 'PA',
        };

        localStorage.setItem('role', roleMap[role] || role);
      }

      if (!(localStorage.token && localStorage.user)) {
        this.showHeader(false);
        this.noPaddingTop(true);
        this.noPaddingBottom(true);

        if (
          window.location.pathname.includes('/setup-account') ||
          window.location.pathname.includes('/setup-network-account') ||
          window.location.pathname.includes('/shared')
        ) {
          // do nothing
        } else if (window.location.pathname.indexOf('/reset-password') === -1) {
          setTimeout((_) => this.router.navigate(['/auth']), 0);
          return;
        }
      }

      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      // Please do not move this line, is required for hubspot.
      if (typeof window.HubSpotConversations != 'undefined') {
        window.HubSpotConversations.widget.refresh();

        if (count == 0) {
          const that = this;
          function temp() {
            const step = localStorage.getItem('tracking_step');
            if (window.location.pathname.includes('/program/new')) {
              that.mixpanel.trackEvent('PS_Step_Hubspot', {
                PS_Step_Hubspot: step,
              });
            }

            if (window.location.pathname.includes('/program')) {
              that.mixpanel.trackEvent('MP_Step_Hubspot', {
                MP_Step_Hubspot: step,
              });
            }
          }
          window.HubSpotConversations.on('conversationStarted', temp);
          count++;
        }
      }

      if (
        this.router.url == '/' ||
        this.router.url == '/auth' ||
        this.router.url == '/org-setup'
      ) {
        this.showHeader(false);
        this.noPaddingTop(true);
      } else {
        this.showHeader(true);
        this.noPaddingTop(false);
      }
      if (
        this.router.url == '/' ||
        this.router.url == '/auth' ||
        this.router.url == '/network' ||
        this.router.url == '/dashboard' ||
        this.router.url == '/academy'
      ) {
        this.noPaddingTop(true);
      }
      if (
        this.router.url.indexOf('/account') > -1 ||
        this.router.url.indexOf('/program/new') > -1 ||
        this.router.url.indexOf('/group') > -1 ||
        this.router.url.indexOf('/networks/new') > -1 ||
        this.router.url.indexOf('/manage-initiative-member') > -1 ||
        this.router.url.indexOf('/subscriptions') > -1 ||
        this.router.url.indexOf('/manage-initiatives') > -1 ||
        this.router.url.indexOf('/assign-sponsored-subscription') > -1 ||
        this.router.url.indexOf('/program') > -1
      ) {
        this.showHeader(false);
        this.noPaddingTop(true);
      }
      if (
        this.router.url.indexOf('/program') > -1 ||
        this.router.url == '/network' ||
        this.router.url == '/account/membership'
      ) {
        this.noPaddingBottom(true);
      } else {
        this.noPaddingBottom(false);
      }
      if (
        this.router.url.indexOf('/dashboard') > -1 ||
        window.location.pathname.includes('/setup-account') ||
        window.location.pathname.includes('/org-setup')
      ) {
        document.body.classList.add('beige-bg');
      } else {
        document.body.classList.remove('beige-bg');
      }
      if (
        this.router.url == '/404' ||
        window.location.pathname.includes('/setup-account') ||
        window.location.pathname.includes('/auth') ||
        window.location.pathname.includes('/responses') ||
        window.location.pathname.includes('/setup-network-account')
      ) {
        this.showHeader(false);
        this.noPaddingTop(true);
        this.noPaddingBottom(true);
      }
      // Need to stop hard coding routes to manage UI state
      if (this.router.url.includes('/reports')) {
        this.showHeader(true);
        this.noPaddingTop(false);
      }
      if (
        this.router.url == '/reports' ||
        this.router.url == '/portfolio' ||
        this.router.url == '/academy'
      ) {
        jQuery(function ($) {
          $('body').css('overflow', 'hidden');
        });
      } else {
        if (this.router.url == '/program/new') {
          jQuery(function ($) {
            $('body').css('overflow-y', 'scroll');
          });
        } else {
          jQuery(function ($) {
            $('body').css('overflow', 'auto');
          });
        }
      }

      if (typeof INTERCOM != 'undefined') {
        this.checkIntercom(this.router.url);
      }

      if (typeof HUBSPOT != 'undefined') {
        this.setHubspotToken(this.router.url);
      }

      if (this.router.url.includes('reset-password')) {
        this.showHeader(false);
        this.noPaddingTop(true);
        this.noPaddingBottom(true);
      }

      window.scrollTo(0, 0);

      if (window.location.pathname.includes('/shared')) {
        this.isSharedReport = true;
        jQuery(function ($) {
          $('body').css('padding-top', '3.5rem');
        });
      }
    });
  }

  public showHeader(type: boolean): void {
    this.headerShow = type;
  }

  public noPaddingTop(type: boolean): void {
    if (type) {
      document.body.classList.add('noPaddingTop');
    } else {
      document.body.classList.remove('noPaddingTop');
    }
  }

  public noPaddingBottom(type: boolean): void {
    if (type) {
      document.body.classList.add('noPaddingBottom');
    } else {
      document.body.classList.remove('noPaddingBottom');
    }
  }

  public checkIntercom(path) {
    if (
      path === '/reports' ||
      path === '/dashboard' ||
      path === '/program/new' ||
      path.indexOf('/manage-program') == 0 ||
      path.indexOf('/reports') == 0 ||
      path.indexOf('/group') == 0 ||
      path.indexOf('/my-account') == 0 ||
      path.indexOf('/responses') == 0
    ) {
      if (this.sessionService.getUser() !== undefined) {
        let user = this.sessionService.getUser();
        (window as any).Intercom('boot', {
          app_id: INTERCOM,
          email: user.email,
          user_hash: user.user_hash,
          hide_default_launcher: false,
        });
      }
    } else {
      (window as any).intercomSettings = null;
      (window as any).Intercom('update', { hide_default_launcher: true });
    }
  }

  public setHubspotToken(path) {
    if (
      path === '/reports' ||
      path === '/dashboard' ||
      path === '/portfolio' ||
      path === '/network' ||
      path === '/academy' ||
      path === '/advanced-reports'
    ) {
      if (this.sessionService.getUser() !== undefined) {
        let user = this.sessionService.getUser();
        const interval = setInterval(() => {
          if (window.HubSpotConversations) {
            clearInterval(interval);
            hubspot();
          }
        }, 1000);
        function hubspot() {
          if (user.hubspot_id_token && window.HubSpotConversations) {
            window.hsConversationsSettings = {
              identificationEmail: user.email,
              identificationToken: user.hubspot_id_token,
            };
            window.HubSpotConversations.widget.load();
          }
        }
      }
    }
  }
}
