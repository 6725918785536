import { Injectable } from '@angular/core';
import { RolesEnum } from '../../enums/roles-enum';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  organization: any;
  intermediary: any;
  isTrial: Boolean;
  role: any;

  get roles() {
    return {
      [RolesEnum.ADMINISTRATOR]: '/enter-as',
      [RolesEnum.INTERMEDIARY_USER]: '/network',
      [RolesEnum.ACCOUNT_ADMINISTRATORS]: 'dashboard',
      [RolesEnum.PROGRAM_LEADER]: '/dashboard',
      [RolesEnum.REPORT_VIEWER]: '/reports',
    };
  }

  constructor() {}

  getToken = () => JSON.parse(localStorage.token);

  setToken = (token) => localStorage.setItem('token', JSON.stringify(token));

  generateToken = (email, password) =>
    'Basic ' + btoa(unescape(encodeURIComponent(email + ':' + password)));

  entered_as = () => !!localStorage._admin_session;

  getUser = () => localStorage.user && JSON.parse(localStorage.user);

  getOrganization = () => JSON.parse(localStorage.getItem('organization'));

  isOrganizationLeader = () =>
    localStorage.getItem('role') == RolesEnum.ACCOUNT_ADMINISTRATORS;

  isProgramLeader = () =>
    localStorage.getItem('role') == RolesEnum.PROGRAM_LEADER;

  isIntermediaryUser = () =>
    localStorage.getItem('role') == RolesEnum.INTERMEDIARY_USER;

  isAdministrator = () =>
    localStorage.getItem('role') == RolesEnum.ADMINISTRATOR;

  isReportViewer = () =>
    localStorage.getItem('role') === RolesEnum.REPORT_VIEWER;

  setUser = (user) => localStorage.setItem('user', JSON.stringify(user));

  setOrganization(org) {
    if (org === undefined || org === null) {
      return;
    }
    this.organization = org;
    if (org.account_type && org.account_type === 'free') {
      this.isTrial = true;
    }
    if (org.account_type && org.account_type === 'paid') {
      this.isTrial = false;
    }
    localStorage.setItem('organization', JSON.stringify(org));
  }

  getIntermediary = () => JSON.parse(localStorage.getItem('intermediary'));

  setIntermediary(im) {
    if (!im) return;
    this.intermediary = im;
    localStorage.setItem('intermediary', JSON.stringify(im));
  }

  setRole(role) {
    this.role = role;
    localStorage.setItem('role', role);
  }

  getRole() {
    return localStorage.getItem('role');
  }

  loggedIn() {
    return !!(localStorage.token && !!localStorage.user);
  }

  getSubscriptions() {
    return JSON.parse(localStorage.subscriptions);
  }

  enter_as(user) {
    let _admin_session = {
      user: {},
      role: '',
    };
    _admin_session.user = JSON.parse(localStorage.getItem('user'));
    _admin_session.role = localStorage.getItem('role');
    localStorage.setItem('_admin_session', JSON.stringify(_admin_session));
    this.setUser(user);
  }

  end() {
    //self.user = undefined;
    this.role = undefined;
    this.organization = undefined;
    //self._admin_session = undefined;
    localStorage.removeItem('user');
    localStorage.removeItem('role');
    localStorage.removeItem('organization');
    localStorage.removeItem('intermediary');
    localStorage.removeItem('token');
    localStorage.removeItem('_admin_session');
  }

  restore_admin_session() {
    if (this.entered_as()) {
      this.setUser(JSON.parse(localStorage.getItem('_admin_session')).user);
      this.setRole(JSON.parse(localStorage.getItem('_admin_session')).role);
      localStorage.setItem('_admin_session', null);
      this.organization = undefined;
      localStorage.removeItem('organization');
      localStorage.removeItem('_admin_session');
    } else {
      throw 'Admin session was not found';
    }
  }
}
