import { inject, Injectable } from '@angular/core';
import * as mixpanel from 'mixpanel-browser';
import { SessionService } from '../session/session.service';

// TODO uncomment when mixpanel approved

// declare const MIX_PANEL_PROJECT_ID;
// declare const MIX_PANEL_ENABLED;

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private session = inject(SessionService);

  constructor() {
    // if (MIX_PANEL_ENABLED == 'true') {
    //   mixpanel.init(MIX_PANEL_PROJECT_ID);
    //   mixpanel.set_config({ persistence: 'localStorage' });
    // }
  }

  trackEvent(eventName: string, data: {}) {
    // const userId: number = this.session.getUser().id;
    // const organizationId: number = this.session.getOrganization()
    //   ? this.session.getOrganization().id
    //   : undefined;
    // data = {
    //   ...data,
    //   userId,
    //   organizationId,
    //   ...(organizationId && { organizationId }),
    // };
    // mixpanel.track(eventName, data);
  }

  setIdentity(userId: number) {
    // mixpanel.identify(userId.toString());
  }

  setUser(user: {}) {
    // mixpanel.people.set(user);
  }
}
