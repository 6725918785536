import { Component, OnInit } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SessionService } from '../../../shared/services/session/session.service';
import { Router, RouterLinkActive } from '@angular/router';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { RolesEnum as role } from 'src/app/shared/enums/roles-enum';
import { DashboardService } from 'src/app/modules/dashboard-im/dashboard-im.services';
import { HeaderService } from './header.service';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/shared/material/material.module';

declare const ACADEMY_ENABLED;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterLinkActive, MaterialModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderComponent implements OnInit {
  public mainNav: Array<any>;
  public userMenuToggled: boolean = false;
  public userMenu: any;
  public getSupport: Array<any>;
  public getSupportToggled: boolean = false;
  public mainNavRole: String;
  public hasReports: boolean;
  public showArrow: boolean = true;
  public showManageInitiatives: boolean = false;
  public initiativeNum: number = 0;

  get expandedMenuItems() {
    let nav = this.mainNav;

    for (let i = 0; i < nav.length; i++) {
      if (nav[i].expanded) return nav[i].menu;
    }
    return [];
  }

  constructor(
    private _sessionService: SessionService,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private _apiService: ApiService,
    private DashboardService: DashboardService,
    private headerService: HeaderService
  ) {
    this.matIconRegistry.addSvgIcon(
      'basic-reports',
      this.setPath('../../../assets/img/dashboard/basic-reports.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'advanced-reports',
      this.setPath('../../../assets/img/dashboard/portfolio-reports.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'right-arrow',
      this.setPath('../../../assets/img/icon-care-right-small.svg')
    );
  }

  ngOnInit() {
    this.validateReports();

    this.matIconRegistry.addSvgIcon(
      'icon-network',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/img/icon-dashboard-im.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-portfolio',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/img/dashboard/nav-reports.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-dashboard',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/img/dashboard/nav-dashboard.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-reports',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/img/dashboard/nav-reports.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-support',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/img/icon-get-support.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-support-center',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/img/icon-support-center.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-support-email',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/img/icon-support-email.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-support-phone',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/img/icon-support-phone.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-support-chat',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/img/icon-support-chat.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'icon-academy',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/img/icon-academy.svg'
      )
    );

    if (this._sessionService.isIntermediaryUser()) {
      this.validateInitiatives();
    } else {
      this.getMainMenu();
      if (this.mainNav) this.mainNav[0].active = true;
      this.getCurrentActiveMenu();
    }
  }

  public getCurrentActiveMenu() {
    if (this.mainNav) {
      const path = window.location.pathname;
      let activeNav;
      for (let nav of this.mainNav) {
        let urls = [nav.url];

        if (nav.menu) for (let m of nav.menu) urls.push(m.url);

        urls.forEach((element) => {
          if (path.includes(element)) {
            activeNav = nav;
          }
        });

        if (path === '/no-reports') nav.active = nav.name === 'Reports';
      }
      if (activeNav) {
        for (let nav of this.mainNav) {
          nav.active = false;
          nav.expanded = false;
        }
        activeNav.active = true;
      }
    }
  }

  public getMainMenu() {
    if (this._sessionService.isAdministrator()) {
      this.mainNav = [
        { name: 'Enter As', url: '/enter-as' },
        { name: 'Networks', url: '/networks' },
        { name: 'Organizations', url: '/new-organization' },
        { name: 'Flask Admin', url: '/flask-admin' },
      ];
    }

    const [advancedReports, advancedDescription] =
      this.getAdvancedDescription();

    if (
      this._sessionService.isOrganizationLeader() ||
      this._sessionService.isProgramLeader()
    ) {
      this.mainNav = [
        { name: 'Dashboard', url: '/dashboard', img: 'icon-dashboard' },
        {
          name: 'Reports',
          url: '#',
          img: 'icon-reports',
          menu: [
            {
              name: 'Basic Reports',
              url: '/reports',
              img: 'basic-reports',
              description: '',
            },
            {
              name: 'Advanced Reports',
              url: advancedReports ? '/advanced-reports' : 'NA',
              disabled: !advancedReports,
              img: 'advanced-reports',
              description: advancedDescription,
            },
          ],
        },
      ];
      if (typeof ACADEMY_ENABLED != 'undefined' && ACADEMY_ENABLED == 1) {
        this.mainNav.push({
          name: 'Academy',
          url: '/academy',
          img: 'icon-academy',
        });
      }
    }

    if (this._sessionService.isIntermediaryUser()) {
      const { oversight_dashboard } = this._sessionService.getIntermediary();
      this.mainNav = [];

      (oversight_dashboard || oversight_dashboard == null) &&
        this.initiativeNum > 0 &&
        this.mainNav.push({
          name: 'Dashboard',
          url: '/network',
          img: 'icon-network',
        });

      this.mainNav.push({
        name: 'Reports',
        url: '/portfolio',
        img: 'icon-portfolio',
      });
    }

    if (this._sessionService.isReportViewer()) {
      this.mainNav = [
        {
          name: 'Reports',
          url: '#',
          img: 'icon-reports',
          menu: [
            {
              name: 'Basic Reports',
              url: '/reports',
              img: 'basic-reports',
              description: '',
            },
            {
              name: 'Advanced Reports',
              url: advancedReports ? '/advanced-reports' : 'NA',
              disabled: !advancedReports,
              img: 'advanced-reports',
              description: advancedDescription,
            },
          ],
        },
      ];

      //if(!this.hasReports) this.router.navigate[('reports/no-reports')];
    }

    this.getUserMenu();
  }

  public getUserOrg() {
    if (this._sessionService !== null) {
      if (!this._sessionService.isAdministrator()) {
        let org = this._sessionService.getOrganization();
        if (org) return org.name;
        let im = this._sessionService.getIntermediary();
        if (im) return im.name;
      }
    }
  }

  public getUserProfile() {
    if (this._sessionService !== null) {
      if (this._sessionService.isAdministrator()) return 'Log In';
      const user = this._sessionService.getUser();
      if (user && user.first_name !== null && user.last_name !== null) {
        const first = user.first_name;
        const last = user.last_name;
        return first + ' ' + last;
      } else {
        return 'Please log in';
      }
    }
  }

  public getUserPicture() {
    let user = this._sessionService.getUser();
    if (user) return user.profile_picture_url;
  }

  public getProfilePicStyles() {
    if (this.getUserPicture()) {
      return {
        'background-color': 'white',
      };
    }
    return '';
  }

  public getInitials() {
    if (this._sessionService !== null) {
      let user = this._sessionService.getUser();
      if (this._sessionService.isAdministrator()) return 'HI';
      if (user && user.first_name !== null && user.last_name !== null)
        return user.first_name.charAt(0) + user.last_name.charAt(0);
    }
  }

  public toggleUserMenu(e) {
    this.userMenuToggled = !this.userMenuToggled;
    e.stopPropagation();
  }

  public getUserMenu() {
    const org = this._sessionService.getOrganization();
    if (org) {
      this.userMenu = org.agreed_collect_consent
        ? [{ name: 'My Account', link: '/account/profile' }]
        : [];
    } else {
      this.userMenu = [];
    }

    if (
      this._sessionService.isAdministrator() ||
      this._sessionService.isIntermediaryUser()
    ) {
      this.userMenu.push({ name: 'My Account', link: '/account/profile' });

      if (this._sessionService.isIntermediaryUser()) {
        const { oversight_dashboard } = this._sessionService.getIntermediary();

        if (
          !(oversight_dashboard == null || oversight_dashboard == true) &&
          this.showManageInitiatives
        ) {
          this.userMenu.push({
            name: 'Manage Initiatives',
            link: '/manage-initiatives',
          });
        }
      }
    }

    if (this._sessionService.isAdministrator()) {
      this.userMenu.push({ name: 'Portfolio Reports', link: '/portfolio' });
    }

    if (this._sessionService.entered_as()) {
      this.userMenu.push({ name: 'Admin', link: '' });
    }

    this.userMenu.push({ name: 'Logout', link: '#' });
  }

  public clickMenu(menu, $event) {
    switch (menu.name) {
      case 'Logout':
        this.logout();
        break;
      case 'My Account':
        this.router.navigate(['/account/profile']);
        break;
      case 'Admin':
        this.return_as_admin();
        break;
      case 'Portfolio Reports':
        this.router.navigate(['/portfolio']);
        break;
      default:
        this.router.navigate([menu.link]);
        break;
    }
  }

  public logout() {
    window.HubSpotConversations.widget.remove();
    this._sessionService.end();
    localStorage.clear();
    this.router.navigate(['/auth']);
  }

  public removeDateStorage() {
    localStorage.removeItem('_program_id');
    localStorage.removeItem('_site_id');
    localStorage.removeItem('initiative_selected_getting_ready');
    localStorage.removeItem('_site_id');
    localStorage.removeItem('portfolio_filters_selection');
    localStorage.removeItem('show_selection');
  }

  public return_as_admin() {
    this._sessionService.restore_admin_session();
    this.router.navigate(['/enter-as']);
    this.mainNav = [
      { name: 'Enter As', url: '/enter-as' },
      { name: 'Networks', url: '/networks' },
      { name: 'Organizations', url: '/new-user' },
      { name: 'Flask Admin', url: '/flask-admin' },
    ];
    this.removeDateStorage();
  }

  public selectNav = (nav, event) => {
    const dropdown: any = document.getElementsByClassName(
      'header-menu-dropdown-content'
    );

    this.mainNav.forEach((el) => {
      el.active = false;
      el.expanded = false;
    });
    nav.active = true;

    if (nav.url == '') {
      event.preventDefault();
      return;
    }

    if (nav.menu) {
      event.stopPropagation();

      if (nav.name == 'Reports') {
        this.hasReports
          ? (nav.expanded = !nav.expanded)
          : this.router.navigate(['reports/no-reports']);
      }
    } else {
      this.router.navigate([nav.url]);
    }

    if (dropdown.length > 0) nav.expanded = false;

    if (nav.name === 'Reports') this.headerService.setClick();
  };

  setPath(url: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  validateReports(): void {
    const currentRole = this._sessionService.getRole();

    if (
      currentRole == role.ACCOUNT_ADMINISTRATORS ||
      currentRole == role.PROGRAM_LEADER ||
      currentRole == role.REPORT_VIEWER
    ) {
      this._apiService.hasReport().subscribe({
        next: (res: any) => {
          this.hasReports = res.has_report;
        },
      });
    }
  }

  goUrl(nav) {
    if (nav.url == 'NA') return;
    this.router
      .navigateByUrl('', { skipLocationChange: true })
      .then(() => this.router.navigate([nav.url]));
  }

  private getAdvancedDescription = (): [boolean, string] => {
    const organization = this._sessionService.getOrganization();

    if (organization) {
      const advancedReports: boolean =
        organization.current_subscription.enable_advanced_reports;
      let advancedDescription: any = '';

      if (!advancedReports) {
        const managerEmail: string = organization.membership_manager_email;
        const managerText = `at <a target="_blank" href="mailto:${managerEmail}">${managerEmail}</a>`;
        advancedDescription = `Aggregate data from multiple programs, and make comparisons across different sites and groups. Contact your membership manager ${managerText} to add this feature.`;
        advancedDescription =
          this.domSanitizer.bypassSecurityTrustHtml(advancedDescription);
        this.showArrow = false;
      }

      return [advancedReports, advancedDescription];
    } else {
      return [false, ''];
    }
  };

  validateInitiatives() {
    const { id } = this._sessionService.getIntermediary();

    this.DashboardService.getInitiatives(id).subscribe({
      next: ({ _items }) => {
        this.initiativeNum = _items.length;
        this.showManageInitiatives = _items.length > 0;
        this.getMainMenu();
        this.mainNav[0].active = true;
        this.getCurrentActiveMenu();
      },
      error: (err) => console.error(err),
    });
  }
}
